import React, { useContext, useEffect, useState } from 'react';
import { Box, Card as MuiCard, CardContent, CardMedia, Divider, Typography, Button } from '@mui/material';
import { useLocation, NavLink } from 'react-router-dom';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { randomUniqueNum, scrollToTop } from '../../utils/utils';
import AppCtx from '../../context';
import WifiIcon from '@mui/icons-material/Wifi';
import TvIcon from '@mui/icons-material/Tv';
import HotelIcon from '@mui/icons-material/Hotel';
import PetsIcon from '@mui/icons-material/Pets';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import NotAccessibleIcon from '@mui/icons-material/NotAccessible';
import BakeryDiningIcon from '@mui/icons-material/BakeryDining';
import KitchenIcon from '@mui/icons-material/Kitchen';
import CoffeeMakerIcon from '@mui/icons-material/CoffeeMaker';
import WcIcon from '@mui/icons-material/Wc';
import NightlightIcon from '@mui/icons-material/Nightlight';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import useGlobalStyle from '../../config/styleHooks';

const services: ServicesType[] = [
  {
    ico: <WifiIcon color='primary' sx={{ fontSize: '40px' }} />,
    text: {
      it: 'Internet',
      en: 'Internet',
    },
  },
  {
    ico: <TvIcon color='primary' sx={{ fontSize: '40px' }} />,
    text: {
      it: 'TV in camera',
      en: 'TV in the room',
    },
  },
  // {
  //   ico: <HotelIcon color='primary' sx={{ fontSize: '40px' }} />,
  //   text: {
  //     it: '2 camere, 4 posti letto',
  //     en: '2 room, 4 beds',
  //   },
  // },
  {
    ico: <PetsIcon color='primary' sx={{ fontSize: '40px' }} />,
    text: {
      it: 'Animali ammessi',
      en: 'Pets allowed',
    },
  },
  {
    ico: <AcUnitIcon color='primary' sx={{ fontSize: '40px' }} />,
    text: {
      it: 'Aria condizionata',
      en: 'Air conditioning',
    },
  },
  {
    ico: <NotAccessibleIcon color='primary' sx={{ fontSize: '40px' }} />,
    text: {
      it: 'Secondo piano senza ascensore',
      en: 'Second floor without lift',
    },
  },
  {
    ico: <BakeryDiningIcon color='primary' sx={{ fontSize: '40px' }} />,
    text: {
      it: 'Colazione inclusa',
      en: 'Breakfast included',
    },
  },
  {
    ico: <KitchenIcon color='primary' sx={{ fontSize: '40px' }} />,
    text: {
      it: 'Frigorifero',
      en: 'Icebox',
    },
  },
  {
    ico: <CoffeeMakerIcon color='primary' sx={{ fontSize: '40px' }} />,
    text: {
      it: 'Macchina per il caffè',
      en: 'Coffee machine',
    },
  },
  {
    ico: <WcIcon color='primary' sx={{ fontSize: '40px' }} />,
    text: {
      it: 'WC: privato per ogni camera',
      en: 'WC: private for each room',
    },
  },
  {
    ico: <NightlightIcon color='primary' sx={{ fontSize: '40px' }} />,
    text: {
      it: 'Prenotazione minima 2 notti',
      en: 'Minimum booking 2 nights',
    },
  },
  {
    ico: <LocationOnIcon color='primary' sx={{ fontSize: '40px' }} />,
    text: {
      it: 'Centro storico',
      en: 'Historic center',
    },
  },
];

export default function ServicesPages() {
  const [serviceList, setServiceList] = useState<ServicesType[]>();
  const { pathname } = useLocation();
  const appContext = useContext(AppCtx);
  const setScrollPosition = appContext?.setScrollPosition;
  const mt = pathname === '/' ? 20 : 10;
  const lang = appContext?.lang || 'it';
  const classes = useGlobalStyle();

  const handleClick = () => {
    setScrollPosition && setScrollPosition(window.scrollY);
    scrollToTop();
  };

  useEffect(() => {
    if (pathname === '/servizi') {
      setServiceList(services);
    } else {
      let rands = randomUniqueNum(services.length, 4);
      const indexServices: ServicesType[] = [];

      for (let i = 0; i < rands.length; i++) {
        const index = rands[i];
        indexServices.push(services[index]);
      }
      setServiceList(indexServices);
    }
  }, [pathname]);

  return (
    <Box mt={mt}>
      <Typography
        className={classes.titleCursive}
        component='h1'
        variant='h3'
        color='primary'
        textAlign='center'
        mb={5}
      >
        {lang === 'it' ? 'Servizi' : 'Services'}
      </Typography>
      <Grid2 container spacing={4}>
        {serviceList &&
          serviceList.map(({ ico, text }, i) => (
            <Grid2 key={i} xs={12} md={3}>
              <MuiCard data-aos='flip-left'>
                <CardMedia sx={{ textAlign: 'center' }}>{ico}</CardMedia>
                <Divider sx={{ width: '50%', margin: 'auto' }} />
                <CardContent>
                  <Typography variant='body1' color='primary' textAlign='center'>
                    {text[lang]}
                  </Typography>
                </CardContent>
              </MuiCard>
            </Grid2>
          ))}
        {pathname === '/' ? (
          <Grid2 xs={12} textAlign='center' mt={4}>
            <Button variant='contained' onClick={handleClick}>
              <NavLink to={'/servizi'} style={{ textDecoration: 'none', color: '#fff' }}>
                {lang === 'it' ? 'Altro' : 'More'}
              </NavLink>
            </Button>
          </Grid2>
        ) : (
          <Grid2 xs={12} textAlign='center' mt={4}>
            <Box textAlign='center'>
              <Button variant='contained' onClick={scrollToTop}>
                <NavLink to={'/'} style={{ textDecoration: 'none', color: '#fff' }}>
                  {lang === 'it' ? 'Torna alla home' : 'Back to home'}
                </NavLink>
              </Button>
            </Box>
          </Grid2>
        )}
      </Grid2>
    </Box>
  );
}
