import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useGlobalStyle = makeStyles((theme: Theme) => ({
  link: {
    textDecoration: 'none',
    textTransform: 'uppercase',
    color: theme.palette.primary.contrastText,
    padding: '5px',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },

  activeLink: {
    backgroundColor: theme.palette.primary.dark,
  },

  titleCursive: {
    fontFamily: 'Great Vibes, cursive',
  },
}));

export default useGlobalStyle;
