import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import AboutPage from "../../pages/AboutPage";
import ErrorPage from "../../pages/ErrorPage";
import IndexPage from "../../pages/IndexPage";
import Root from "./root";
import CreazioniPages from "../../pages/CreazioniPage/index";
import ServicesPages from "../../pages/ServicesPage";
import GalleryPage from "../../pages/GalleryPage";
import SurroundingsPage from "../../pages/SurroundingsPage/index";
import Contacts from "../../pages/Contacts";
import NotFoundPage from "../../pages/NotFoundPage";
import RecensioniPage from "../../pages/RecensioniPage";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root />} errorElement={<ErrorPage />}>
      <Route errorElement={<ErrorPage />}>
        <Route index element={<IndexPage />} />
        <Route path="about" element={<AboutPage />} />
        <Route path="servizi" element={<ServicesPages />} />
        <Route path="galleria" element={<GalleryPage />} />
        <Route path="dintorni" element={<SurroundingsPage />} />
        <Route path="contatti" element={<Contacts />} />
        <Route path="creazioni" element={<CreazioniPages />} />
        <Route path="recensioni" element={<RecensioniPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Route>
  )
);

export default router;
