import { Box, Container, Link, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import Map from '../../components/Map';
import useGlobalStyle from '../../config/styleHooks';
import AppCtx from '../../context';
import airbnb from '../../assets/svg/airbnb.svg';
import booking from '../../assets/svg/bookingcom.svg';
import tripadvisor from '../../assets/svg/tripadvisor.svg';

import { FormContacts } from '../../components/FormContacts';

export default function Contacts() {
  const appContext = useContext(AppCtx);
  const lang = appContext?.lang || 'it';
  const { pathname } = useLocation();
  const mt = pathname === '/' ? 20 : 10;
  const classes = useGlobalStyle();
  const xsSvgSize = 25;
  const mdSvgSize = 50;

  return (
    <Box mt={mt}>
      <Typography
        className={classes.titleCursive}
        color='primary'
        component='h1'
        variant='h3'
        textAlign='center'
        mb={5}
      >
        {lang === 'it' ? 'Contatti' : 'Contacts'}
      </Typography>

      <Container maxWidth='md'>
        <FormContacts lang={lang} />
      </Container>

      <Typography
        className={classes.titleCursive}
        variant='h2'
        component='h1'
        color='primary'
        mb={5}
        textAlign='center'
        m={5}
      >
        {lang === 'it' ? 'Prenota il tuo soggiorno su ' : 'Book your stay on '}
        <Link
          href='https://www.airbnb.it/rooms/641908473172258175?guests=1&adults=1&s=67&unique_share_id=5e7600aa-657d-46fa-ba3a-9654aeaa9779'
          target='_blank'
          underline='hover'
        >
          airbnb <Box component='img' src={airbnb} alt='airbnb' width={{ xs: xsSvgSize, md: mdSvgSize }} />
        </Link>{' '}
        <Link
          href='https://www.booking.com/hotel/it/b-amp-b-via-dell-azzurra.en-gb.html'
          target='_blank'
          underline='hover'
        >
          booking <Box component='img' src={booking} alt='booking' width={{ xs: xsSvgSize, md: mdSvgSize }} />
        </Link>{' '}
        <Link
          href='https://www.tripadvisor.it/ShowUserReviews-g187874-d25068307-r869640933-Bed_and_Breakfast_Via_dell_Azzurra-Bari_Province_of_Bari_Puglia.html'
          target='_blank'
          underline='hover'
        >
          tripadvisor{' '}
          <Box component='img' src={tripadvisor} alt='tripavisor' width={{ xs: xsSvgSize, md: mdSvgSize }} />
        </Link>
      </Typography>
      <Map />
    </Box>
  );
}
