import { Box, Typography } from '@mui/material';
import { Container } from '@mui/system';
import React from 'react';
import { useRouteError } from 'react-router-dom';

type TError = { statusText: string; message: string; status: number };

export default function ErrorPage() {
  const error = useRouteError() as TError;

  return (
    <Container maxWidth='lg'>
      <Box my={4} display='flex' flexDirection='column' justifyContent='center' alignItems='center' height='100vh'>
        <Typography variant='h4' component='h1' gutterBottom>
          Oops!
        </Typography>
        <Typography variant='body1'>Sorry, an unexpected error has occurred.</Typography>
        <Typography variant='body1' component='i'>
          {error.status}, {error.statusText || error.message}
        </Typography>
      </Box>
    </Container>
  );
}
