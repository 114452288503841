import { importAllImg } from '../../utils/utils';

const img = importAllImg(
  require.context('../../../public/assets/img/gallery', false, /\.(png|jpe?g|svg|PNG|JPE?G|SVG)$/)
);

const images = img.map((item: string, index: number) => ({
  id: `${index}`,
  src: `/assets/img/gallery/${item}`,
  name: item.split('.')[0],
}));

export default images;
