import React, { useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import AppCtx from './context';
import router from './routes/router';
import { useLanguage } from './context/index';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function App() {
  const [currentLang, setCurrentLang] = useState<LangType>('it');
  const [scrollPosition, setScrollPosition] = useState(0);
  const language = useLanguage(setCurrentLang);

  const appContext: AppContextType = {
    yPos: scrollPosition,
    lang: currentLang,
    ...language,
    setScrollPosition,
  };

  useEffect(() => {
    AOS.init({ duration: 2000, easing: 'ease-in-out', once: true, mirror: false });
  }, []);

  return (
    <AppCtx.Provider value={appContext}>
      <RouterProvider router={router} />
    </AppCtx.Provider>
  );
}
