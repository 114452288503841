import { Box, Button, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React, { useContext } from 'react';
import useGlobalStyle from '../../config/styleHooks';
import logo from '../../assets/img/logobb.jpg';
import { NavLink, useLocation } from 'react-router-dom';
import { scrollToTop } from '../../utils/utils';
import AppCtx from '../../context';
import parser from 'html-react-parser';
import { textAbout, textAboutOptions, textRooms } from './text';

function AboutPage() {
  const classes = useGlobalStyle();
  const appContext = useContext(AppCtx);
  const lang = appContext?.lang || 'it';
  const { pathname } = useLocation();
  const mt = pathname === '/' ? 20 : 10;

  return (
    <Box>
      <Box m={6} data-aos='fade-down'>
        <Typography className={classes.titleCursive} component='h1' variant='h2' color='primary'>
          B & B
        </Typography>
        <Typography className={classes.titleCursive} component='h1' variant='h2' color='primary'>
          Via dell'Azzurra
        </Typography>
      </Box>
      <Grid2 container spacing={4}>
        <Grid2 xs={12} md={6} data-aos='fade-right'>
          {parser(textAbout[lang], textAboutOptions)}
          {/* <Typography variant='body1'>CIS: BA07200661000023973</Typography> */}
          <Typography variant='body1'>CIN: IT072006C1000065315</Typography>
        </Grid2>
        <Grid2 xs={12} md={6} data-aos='fade-left'>
          <Box component='img' src={logo} alt='logo' loading='lazy' width='100%' borderRadius={20} />
        </Grid2>
      </Grid2>
      <Grid2 xs={12} textAlign='center' mt={4}>
        <Button variant='contained' onClick={scrollToTop}>
          <NavLink to={'/creazioni'} style={{ textDecoration: 'none', color: '#fff' }}>
            {lang === 'it' ? 'I mie lavori' : 'my jobs'}
          </NavLink>
        </Button>
      </Grid2>

      <Box mt={mt} data-aos='zoom-in'>
        <Typography
          className={classes.titleCursive}
          component='h1'
          variant='h3'
          color='primary'
          textAlign='center'
          m={5}
        >
          {lang === 'it' ? 'Le stanze' : 'The rooms'}
        </Typography>

        {parser(textRooms[lang], textAboutOptions)}
      </Box>
    </Box>
  );
}

export default AboutPage;
