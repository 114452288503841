import { Box, Link, Typography, useTheme, Stack } from '@mui/material';
import React from 'react';
import logo from '../../assets/img/logo_mceasyweb.png';

export default function Copyright() {
  const theme = useTheme();

  return (
    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent='center' alignItems='center' pb={4}>
      <Typography variant='body2' color='#fff' align='center' bgcolor={theme.palette.primary.main}>
        {'Copyright © '}
        <Link color='inherit' href='#'>
          B&B Via dell'Azzurra
        </Link>{' '}
        <Link color='inherit' href='mailito:lacrimediluna3@gmail.com'>
          lacrimediluna3@gmail.com
        </Link>{' '}
        {new Date().getFullYear()}.
      </Typography>

      <Typography
        display={'flex'}
        color='#fff'
        justifyContent='center'
        alignItems='center'
        sx={{ marginTop: { xs: '10px', md: 0 } }}
      >
        Powerd by &nbsp;
        <Link target={'_blank'} href='https://mceasyweb.it/' color='#fff'>
          mcEasyWeb
        </Link>
        <Box
          component='img'
          src={logo}
          alt='mcEasyWeb logo'
          width={50}
          ml={1}
          // display={{ xs: 'none', md: 'inherit' }}
          // style={{ display: 'none' }}
        />
      </Typography>
    </Stack>
  );
}
