import { Box, ImageList, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useTheme, SxProps, Theme } from '@mui/material/styles';
import { shuffle, sliceIntoChunks } from '../../utils/utils';
import Item from '../Item';
import StepButton from '../StepButton';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

interface ICarousel {
  rowHeight?: number;
  photos: IPhoto[];
  itemPerPage?: number;
  sx?: SxProps<Theme>;
  randomize?: boolean;
}

const Carousel: React.FC<ICarousel> = ({ rowHeight = 250, photos, itemPerPage = 6, sx = [], randomize = false }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [cols, setCols] = useState(1);
  const [itemsCarusel, setItemsCarusel] = useState<[IPhoto[]]>([[]]);
  const [isOpenLightBox, setIsOpenLightBox] = useState(false);
  const [indexLightBox, setIndexLightBox] = useState(0);
  const maxSteps = itemsCarusel?.length;
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));

  // Imposta le colonne in base alla grandezza dello schermo
  useEffect(() => {
    if (isExtraSmallScreen) {
      setCols(1);
    } else if (isSmallScreen) {
      setCols(2);
    } else if (isMediumScreen) {
      setCols(3);
    } else {
      setCols(4);
    }
  }, [isExtraSmallScreen, isSmallScreen, isMediumScreen]);

  useEffect(() => {
    setItemsCarusel(sliceIntoChunks(randomize ? shuffle(photos) : photos, itemPerPage) as [IPhoto[]]);
  }, [photos, itemPerPage, randomize]);

  return (
    <Box sx={{ position: 'relative', ...sx }}>
      <SwipeableViews
        index={activeStep}
        enableMouseEvents
        onSwitching={(index: any, type: any) => {
          if (type === 'end') {
            setActiveStep(index);
          }
        }}
      >
        {itemsCarusel &&
          itemsCarusel.map((step, index) => (
            <ImageList key={index} variant='quilted' cols={cols} rowHeight={rowHeight}>
              <Item
                items={step}
                setIsOpenLightBox={setIsOpenLightBox}
                page={index}
                itemPerPage={itemPerPage}
                setIndexLightBox={setIndexLightBox}
              />
            </ImageList>
          ))}
      </SwipeableViews>
      <StepButton setActiveStep={setActiveStep} step='next' maxSteps={maxSteps} activeStep={activeStep} />
      <StepButton setActiveStep={setActiveStep} step='back' maxSteps={maxSteps} activeStep={activeStep} />
      <Lightbox open={isOpenLightBox} index={indexLightBox} close={() => setIsOpenLightBox(false)} slides={photos} />
    </Box>
  );
};

export default Carousel;
