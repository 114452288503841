import React, { useContext } from 'react';
import { Box, Divider, Stack, SxProps, Typography, useTheme } from '@mui/material';
import Copyright from '../Copyright';
import WaveBorder from '../WaveBorder';
import useGlobalStyle from '../../config/styleHooks';
import AppCtx from '../../context';
import facebook from '../../assets/svg/facebook.svg';
import airbnb from '../../assets/svg/airbnb.svg';
import booking from '../../assets/svg/bookingcom.svg';
import google from '../../assets/svg/google.svg';
import tripadvisor from '../../assets/svg/tripadvisor.svg';

type FooterType = {
  sx?: SxProps;
};

export default function Footer({ sx }: FooterType) {
  const classes = useGlobalStyle();
  const appContext = useContext(AppCtx);
  const lang = appContext?.lang || 'it';
  const theme = useTheme();
  const xsSvgSize = 25;
  const mdSvgSize = 50;

  return (
    <Box component='footer'>
      <WaveBorder />
      <Box bgcolor={theme.palette.primary.main} textAlign='center' pt={2}>
        <Typography className={classes.titleCursive} color='#fff' component='h1' variant='h3' textAlign='center' mb={5}>
          {lang === 'it' ? 'Seguimi su' : 'Follow me up'}
        </Typography>

        <Stack direction='row' spacing={4} justifyContent='center' mb={4}>
          <Box component='a' href='https://www.facebook.com/via.dell.azzurra' target='_blank'>
            <Box component='img' src={facebook} alt='facebook' width={{ xs: xsSvgSize, md: mdSvgSize }} />
          </Box>

          <Box
            component='a'
            href='https://www.airbnb.it/rooms/641908473172258175?guests=1&adults=1&s=67&unique_share_id=5e7600aa-657d-46fa-ba3a-9654aeaa9779'
            target='_blank'
          >
            <Box component='img' src={airbnb} alt='airbnb' width={{ xs: xsSvgSize, md: mdSvgSize }} />
          </Box>

          <Box
            component='a'
            href='https://www.booking.com/hotel/it/b-amp-b-via-dell-azzurra.en-gb.html'
            target='_blank'
          >
            <Box component='img' src={booking} alt='booking' width={{ xs: xsSvgSize, md: mdSvgSize }} />
          </Box>

          <Box component='a' href='https://www.google.com/travel/hotels/s/26t2vf2soQpRKAmY9' target='_blank'>
            <Box component='img' src={google} alt='google' width={{ xs: xsSvgSize, md: mdSvgSize }} />
          </Box>

          <Box
            component='a'
            href='https://www.tripadvisor.it/ShowUserReviews-g187874-d25068307-r869640933-Bed_and_Breakfast_Via_dell_Azzurra-Bari_Province_of_Bari_Puglia.html'
            target='_blank'
          >
            <Box component='img' src={tripadvisor} alt='google' width={{ xs: xsSvgSize, md: mdSvgSize }} />
          </Box>
        </Stack>

        <Divider sx={{ borderColor: '#fff', maxWidth: '70%', margin: 'auto', marginBottom: 10 }} />

        <Copyright />
      </Box>
    </Box>
  );
}
