import {
  AppBar,
  Box,
  Fade,
  Slide,
  Stack,
  Toolbar,
  useScrollTrigger,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import logo from "../../assets/img/logo.png";
import BasicMenu from "../Menu";
import { NavLink, useLocation } from "react-router-dom";
import useGlobalStyle from "../../config/styleHooks";
import AppCtx from "../../context/index";
import SwitchLanguage from "../SwitchLanguage/index";
import { scrollToTop } from "../../utils/utils";

const navLinks: NavLinkType[] = [
  { link: "/", name: { it: "home", en: "home" } },
  { link: "/about", name: { it: "chi sono", en: "about" } },
  { link: "/servizi", name: { it: "servizi", en: "services" } },
  { link: "/galleria", name: { it: "galleria", en: "gallery" } },
  { link: "/dintorni", name: { it: "dintorni", en: "surroundings" } },
  { link: "/contatti", name: { it: "contatti", en: "contact" } },
  {
    link: "/recensioni",
    name: { it: "dicono di me", en: "They say about me" },
  },
];

interface Props {
  window?: () => Window;
}

export default function Header({ window }: Props) {
  const [headerHeight, setHeaderHeight] = useState(0);
  const classes = useGlobalStyle();
  const refNavBar = useRef<HTMLDivElement>(null);
  const appContext = useContext(AppCtx);
  const lang = appContext?.lang || "it";
  const setScrollPosition = appContext?.setScrollPosition;
  const { pathname } = useLocation();

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  const linkClick = () => {
    setScrollPosition && setScrollPosition(0);
    scrollToTop();
  };

  useEffect(() => {
    if (refNavBar.current) {
      setHeaderHeight(refNavBar.current?.clientHeight);
    }
  }, [refNavBar]);

  return (
    <Box component="header" sx={{ minHeight: headerHeight }}>
      <Slide appear={false} direction="down" in={!trigger}>
        <AppBar id="navBar" component="nav" position="fixed" ref={refNavBar}>
          <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box
              height={{ xs: "127px", md: "184px" }}
              component={"img"}
              src={logo}
              alt="logo"
              py={2}
              loading="lazy"
            />
            <Box
              sx={{ position: "absolute", top: { xs: 10, md: 24 }, right: 24 }}
            >
              <SwitchLanguage />
            </Box>
            <Box sx={{ display: { xs: "none", md: "block" } }}>
              <Stack direction="row" spacing={1}>
                {navLinks.map(({ link, name }) => (
                  <NavLink
                    key={link}
                    to={link}
                    onClick={linkClick}
                    className={({ isActive }) =>
                      [
                        classes.link,
                        isActive && link === pathname ? classes.activeLink : "",
                      ].join(" ")
                    }
                  >
                    {name[lang]}
                  </NavLink>
                ))}
              </Stack>
            </Box>
            <BasicMenu
              listLinks={navLinks}
              sx={{ mt: 3, mr: 2, display: { md: "none" } }}
            />
          </Toolbar>
        </AppBar>
      </Slide>
    </Box>
  );
}
