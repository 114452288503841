import * as React from 'react';
import { domToReact, HTMLReactParserOptions, Element } from 'html-react-parser';
import { Typography } from '@mui/material';
import useGlobalStyle from '../../config/styleHooks';

export const textAbout = {
  it: `
  <p id="title">“Il maestrale strappa le nuvole e fa una giostra di cavalli sul mare”</p>
  <p id="main">Il B&B Via dell’Azzurra sorge tra le strette stradine del borgo antico che si affaccia sul porto, all’ombra del campanile della Cattedrale, dalle sue finestre la sera il rintocco delle campane di San Nicola e Santa Chiara rimbocca l’aria, parlano di tempi lontani le rovine di Santa Maria del Buon Consiglio. San Nicola accoglie i forestieri, così facciamo noi, dandovi la possibilità di soggiornare nel cuore della città vecchia, ascoltando il chiacchierio giù sulla strada, osservando le anziane signore che fanno le orecchiette a mano, immergendovi nella vita pulsante di tutti i giorni, le edicole votive lungo le stradine, i pescatori che rammendano le reti, i bambini che corrono felici, i profumi dei piatti appena preparati si accompagnano a quelli del bucato steso al sole. Qui nessuno vi potrà trovare, è come essere invisibili, sospesi. Il tempo sembra essersi fermato: vestigia del passato vi verranno incontro, il castello di Federico II, rimaneggiato da Isabella D’Aragona e sua figlia Bona Sforza, le chiese di architettura romanico pugliese, la muraglia: la strada dei passi perduti, corti, i vicoli, il passato che si racconta sul lastricato delle chianche. E il mare azzurro dove dondolano le barche azzurre dei pescatori, sullo sfondo di un cielo azzurro. Benvenuti a Via dell’Azzurra.</p>
`,

  en: `
  <p id="title">"The mistral tears the clouds and makes a carousel of horses on the sea"</p>
  <p id="main">The B&B Via dell'Azzurra is located among the narrow streets of the ancient village overlooking the port, in the shadow of the Cathedral bell tower, from its windows in the evening the tolling of the bells of San Nicola and Santa Chiara tucks the air, they speak of ancient times the ruins of Santa Maria del Buon Consiglio. San Nicola welcomes foreigners, so do we, giving you the opportunity to stay in the heart of the old city, listening to the chatter down the street, observing the elderly ladies who make orecchiette by hand, immersing you in the pulsating life of every day, the newsstands votive along the narrow streets, fishermen mending their nets, children running happily, the scents of freshly prepared dishes are accompanied by those of laundry hanging out in the sun. Here no one will be able to find you, it is like being invisible, suspended. Time seems to have stopped: the vestiges of the past will meet you, the castle of Frederick II, remodeled by Isabella D'Aragona and her daughter Bona Sforza, the churches of Apulian Romanesque architecture, the wall: the road of lost steps, the courtyards, the alleys, the past that is told on the pavement of the chianche. And the blue sea where the blue fishing boats swing, against the backdrop of a blue sky. Welcome to Via dell’Azzurra.
</p>
  `,
};

export const textRooms = {
  it: `
  <p id="main">Le stanze arredate con ceramiche vietresi, tende e copriletto antichi regalano il fascino di una pagina uscita dal baule della nonna, dispongono di bagno privato con asciugamani, ciabattine, set di cortesia, TV, wifi, climatizzatore. Le stanze si chiamano Sea star e Octopus, elementi che richiamano l’azzurro del nostro mare. La cucina è a disposizione degli ospiti con tisane, tè, caffè. In frigorifero acqua e tè di benvenuto. Prodotto locale in omaggio.
</p>
`,

  en: `
  <p id="main">Rooms furnished with Vietri ceramics, antique curtains and bedspreads give the charm of a page out of the grandmother's trunk, have a private bathroom with towels, bathrobes, slippers, courtesy set, TV, wifi, air conditioning. The rooms are called Sea star and Octopus, elements that recall the blue of our sea. Small shared kitchen is available to guests with herbal teas, tea, coffee. There are water and tè in the refrigerator for welcome. Complimentary local produce.</p>
  `,
};

export const textAboutOptions: HTMLReactParserOptions = {
  replace: (domNode) => {
    if (domNode instanceof Element) {
      const classes = useGlobalStyle();
      const { attribs, children } = domNode;

      if (attribs.id === 'title') {
        return (
          <Typography className={classes.titleCursive} component='h2' variant='h4' color='primary'>
            {domToReact(children, textAboutOptions)}
          </Typography>
        );
      } else if (attribs.id === 'about-main') {
        return (
          <Typography variant='body1' mt={2}>
            {domToReact(children, textAboutOptions)}
          </Typography>
        );
      }
    }
    return;
  },
};
