import React, { useContext } from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import lavori_1 from '../../assets/img/lavori_1.jpg';
import { Box, Button, Divider, Typography, useTheme } from '@mui/material';
import useGlobalStyle from '../../config/styleHooks';
import AppCtx from '../../context';
import l1 from '../../assets/img/lavori/l1.jpg';
import l2 from '../../assets/img/lavori/l2.jpg';
import l3 from '../../assets/img/lavori/l3.jpg';
import l4 from '../../assets/img/lavori/l4.jpg';
import { NavLink } from 'react-router-dom';
import { scrollToTop } from '../../utils/utils';

const testoCreazioni_1 = {
  it: 'Siamo cocci di bottiglia levigati dal mare, carta vetro di sabbia e sale a limare i nostri confini, sfumando gli spigoli del nostro distratto esserci.',
  en: 'We are shards of bottles smoothed by the sea, glass paper made of sand and salt to smooth the boundaries of our distracted being there.',
};

const testoCreazioni_2 = {
  it: "Indossa un pezzettino di mare con una delle creazioni di Via dell'Azzurra",
  en: "Wear a little piece of the sea with the creations of Via dell'Azzurra.",
};

export default function CreazioniPages() {
  const theme = useTheme();
  const classes = useGlobalStyle();
  const appContext = useContext(AppCtx);
  const lang = appContext?.lang || 'it';

  return (
    <Grid2 container columnSpacing={1} rowSpacing={1}>
      <Grid2 xs={12} md={6} position='relative' data-aos='fade-right'>
        <Box component='img' src={lavori_1} alt='sfondo' width='100%' height='100%' />
        <Box
          position='absolute'
          top={0}
          left={0}
          height={'100%'}
          width={'100%'}
          sx={{
            clipPath: 'polygon(0 0, 56% 0, 47% 100%, 0% 100%)',
            background: 'linear-gradient(0deg, rgba(96,179,255,1) 0%, rgba(5,94,176,1) 100%)',
          }}
        >
          <Box p={{ xs: 1, sm: 3, lg: 5 }}>
            <Typography
              className={classes.titleCursive}
              color='#fff'
              component='h1'
              variant='h1'
              height={{ xs: 40, sm: 80 }}
            >
              “
            </Typography>
            <Typography width='50%' color='#fff' fontSize={{ md: '1.8rem' }}>
              {testoCreazioni_1[lang]}
            </Typography>
            <Divider
              sx={{
                ml: '10%',
                width: '25%',
                my: 2,
                backgroundColor: theme.palette.primary.dark,
                height: 4,
                borderRadius: 2,
              }}
            />
            <Typography width='50%' color='#fff' fontSize={{ md: '1.8rem' }}>
              {testoCreazioni_2[lang]}
            </Typography>
          </Box>
        </Box>
      </Grid2>
      <Grid2 xs={12} md={6}>
        <Grid2 container spacing={1}>
          <Grid2 xs={12} md={6} data-aos='fade-down'>
            <Box height='100%' width='100%' display='flex' alignItems='end' justifyContent='end'>
              <Box
                component='img'
                src={l1}
                alt='lavoro 1'
                width={{ xs: '100%', md: '80%' }}
                height={{ xs: '100%', md: '80%' }}
              />
            </Box>
          </Grid2>
          <Grid2 xs={12} md={6} data-aos='fade-left'>
            <Box component='img' src={l2} alt='lavoro 1' width='100%' height='100%' />
          </Grid2>
          <Grid2 xs={12} md={6} data-aos='fade-right'>
            <Box component='img' src={l3} alt='lavoro 1' width='100%' height='100%' />
          </Grid2>
          <Grid2 xs={12} md={6} data-aos='fade-up'>
            <Box
              component='img'
              src={l4}
              alt='lavoro 1'
              width={{ xs: '100%', md: '80%' }}
              height={{ xs: '100%', md: '80%' }}
            />
          </Grid2>
        </Grid2>
      </Grid2>
      <Grid2 xs={12} display='flex' justifyContent='center' mt={5}>
        <Button variant='contained' onClick={scrollToTop}>
          <NavLink to={'/'} style={{ textDecoration: 'none', color: '#fff' }}>
            {lang === 'it' ? 'Torna alla home' : 'Back to home'}
          </NavLink>
        </Button>
      </Grid2>
    </Grid2>
  );
}
