import React from 'react';
import { Box, Typography } from '@mui/material';
import { Container } from '@mui/material';
import { useRouteError } from 'react-router-dom';
import { useLottie } from 'lottie-react';
import ErrorPage404 from '../../assets/animation/error/error-page-404.json';

type TError = { statusText: string; message: string; status: number };

export default function NotFoundPage() {
  const error = useRouteError() as TError;
  const options = {
    animationData: ErrorPage404,
    loop: true,
  };

  const { View } = useLottie(options);

  return (
    <Container maxWidth='lg'>
      <Box my={4} display='flex' flexDirection='column' justifyContent='center' alignItems='center' height='100vh'>
        {View}
      </Box>
    </Container>
  );
}
