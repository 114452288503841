import React from 'react';
import { Box } from '@mui/material';

const Map = () => {
  return (
    <Box sx={{ marginTop: 'var(--margin-section)' }}>
      <iframe
        src='https://maps.google.com/maps?q=bari%20via%20martinez%2014&t=&z=19&ie=UTF8&iwloc=&output=embed'
        title='mappa'
        height={'360px'}
        width={'100%'}
        allowFullScreen
        loading='lazy'
      />
    </Box>
  );
};

export default Map;
