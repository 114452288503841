import React, { useContext } from 'react';
import { Box, Button, Typography } from '@mui/material';
import AppCtx from '../../context';
import { NavLink, useLocation } from 'react-router-dom';
import useGlobalStyle from '../../config/styleHooks';
import Card from '../../components/Card';
import DintorniData from './data';
import { scrollToTop } from '../../utils/utils';

export default function SurroundingsPage() {
  const appContext = useContext(AppCtx);
  const lang = appContext?.lang || 'it';
  const setScrollPosition = appContext?.setScrollPosition;
  const { pathname } = useLocation();
  const mt = pathname === '/' ? 20 : 10;
  const classes = useGlobalStyle();

  let rand = Math.floor(Math.random() * DintorniData.length);

  const handleClick = () => {
    setScrollPosition && setScrollPosition(window.scrollY);
    scrollToTop();
  };

  return (
    <Box mt={mt}>
      <Typography
        className={classes.titleCursive}
        color='primary'
        component='h1'
        variant='h3'
        textAlign='center'
        mb={5}
      >
        {lang === 'it' ? 'Dintorni' : 'Surroundings'}
      </Typography>

      {pathname === '/dintorni' ? (
        <>
          {DintorniData.map(({ content, title, url, image, direction, animation }, id) => (
            <Card
              key={id}
              content={content[lang]}
              title={title[lang]}
              url={url[lang]}
              image={image}
              animation={animation ? animation : 'fade-right'}
              flexDirection={direction ? direction : 'row'}
              lang={lang}
            />
          ))}
          <Box textAlign='center'>
            <Button variant='contained' onClick={scrollToTop}>
              <NavLink to={'/'} style={{ textDecoration: 'none', color: '#fff' }}>
                {lang === 'it' ? 'Torna alla home' : 'Back to home'}
              </NavLink>
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Card
            animation='fade-right'
            content={DintorniData[rand].content[lang]}
            title={DintorniData[rand].title[lang]}
            url={DintorniData[rand].url[lang]}
            image={DintorniData[rand].image}
            lang={lang}
          />
          <Box textAlign='center'>
            <Button variant='contained' onClick={handleClick}>
              <NavLink to={'/dintorni'} style={{ textDecoration: 'none', color: '#fff' }}>
                {lang === 'it' ? 'Altro' : 'More'}
              </NavLink>
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}
