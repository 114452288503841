import React, { createContext, useMemo } from 'react';

const AppCtx = createContext<AppContextType | null>(null);

export const useLanguage = (setLang: React.Dispatch<React.SetStateAction<LangType>>) => {
  const language = useMemo(
    () => ({
      toggleLangMode: () => {
        setLang((prevLang) => (prevLang === 'it' ? 'en' : 'it'));
      },
    }),
    []
  );
  return language;
};

export default AppCtx;
