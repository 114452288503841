import React, { useState } from 'react';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Button, Paper, styled, TextField } from '@mui/material';

type Control = {
  value: string;
  error: boolean;
  errorMessage: {
    it: string;
    en: string;
  };
};

type FormControl = {
  name: Control;
  surname: Control;
  email: Control;
  telephone: Control;
  message: Control;
};

type FormControlsProps = {
  lang: 'it' | 'en';
};

const defaultFormValues = {
  name: {
    value: '',
    error: false,
    errorMessage: {
      it: 'Devi inserire un nome',
      en: 'You must enter a name',
    },
  },
  surname: {
    value: '',
    error: false,
    errorMessage: {
      it: 'Devi inserire un cognome',
      en: 'You must enter a surname',
    },
  },
  email: {
    value: '',
    error: false,
    errorMessage: {
      it: 'Devi inserire una email',
      en: 'You must enter a email',
    },
  },
  telephone: {
    value: '',
    error: false,
    errorMessage: {
      it: 'Devi inserire un telefono',
      en: 'You must enter a telephone',
    },
  },
  message: {
    value: '',
    error: false,
    errorMessage: {
      it: 'Devi inserire un messaggio',
      en: 'You must enter a message',
    },
  },
};

// Se tutti i campi sono compilati ritorna true
const validateForm = (formControl: FormControl) => {
  type T = keyof FormControl;
  const keys = Object.keys(formControl);
  return keys.every((key) => Boolean(formControl[key as T].value));
};

const StyledTextField = styled(TextField)(({ theme }) => ({
  '.MuiInputBase-root': {
    color: theme.palette.primary.main,
  },
  label: {
    color: theme.palette.primary.main,
  },
  '.MuiInputBase-root:hover:before,.MuiInputBase-root:before,.MuiInputBase-root:after': {
    borderColor: theme.palette.primary.main,
  },
}));

export const FormContacts: React.FC<FormControlsProps> = ({ lang }) => {
  const [formValues, setFormValues] = useState(defaultFormValues);
  const [isValidForm, setIsValidForm] = useState(false);

  const handleChange = (e: React.FormEvent) => {
    type T = keyof FormControl;
    const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;

    setFormValues((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name as T],
        value,
      },
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const body = `
    Nome:\t${formValues.name.value}
    Cognome:\t${formValues.surname.value}
    Email:\t${formValues.email.value}
    Telefono:\t${formValues.telephone.value}
    Messaggio:\t${formValues.message.value}
    `;
    console.log('body', body);
    window.open(`mailto:lacrimediluna3@gmail.com?subject=messaggio da mceasyweb&body=${encodeURIComponent(body)}`);
  };

  const handleBlur = (e: React.FormEvent) => {
    setIsValidForm(validateForm(formValues));
  };

  return (
    <Paper
      variant='outlined'
      component='form'
      noValidate
      onBlur={handleBlur}
      onChange={handleChange}
      onSubmit={handleSubmit}
    >
      <Grid2 container spacing={2} p={3}>
        <Grid2 xs={12} sm={6}>
          <StyledTextField
            name='name'
            label={lang === 'it' ? 'Nome' : 'Name'}
            variant='standard'
            fullWidth
            required
            error={formValues.name.error}
            value={formValues.name.value}
            helperText={formValues.name.error && formValues.name.errorMessage[lang]}
          />
        </Grid2>
        <Grid2 xs={12} sm={6}>
          <StyledTextField
            name='surname'
            label={lang === 'it' ? 'Cognome' : 'Surname'}
            variant='standard'
            fullWidth
            required
            error={formValues.surname.error}
            value={formValues.surname.value}
            helperText={formValues.surname.error && formValues.surname.errorMessage[lang]}
          />
        </Grid2>
        <Grid2 xs={12} sm={6}>
          <StyledTextField
            name='email'
            type='email'
            label={lang === 'it' ? 'Email' : 'Email'}
            variant='standard'
            fullWidth
            required
            error={formValues.email.error}
            value={formValues.email.value}
            helperText={formValues.email.error && formValues.email.errorMessage[lang]}
          />
        </Grid2>
        <Grid2 xs={12} sm={6}>
          <StyledTextField
            name='telephone'
            type='tel'
            label={lang === 'it' ? 'Telefono' : 'Telephone'}
            variant='standard'
            fullWidth
            required
            error={formValues.telephone.error}
            value={formValues.telephone.value}
            helperText={formValues.telephone.error && formValues.telephone.errorMessage[lang]}
          />
        </Grid2>
        <Grid2 xs={12}>
          <StyledTextField
            name='message'
            label={lang === 'it' ? 'Messaggio' : 'Message'}
            multiline
            maxRows={5}
            variant='standard'
            fullWidth
            error={formValues.message.error}
            value={formValues.message.value}
            helperText={formValues.message.error && formValues.message.errorMessage[lang]}
          />
        </Grid2>
        <Grid2 xs={12} display='flex' justifyContent={'end'}>
          <Button type='submit' variant='contained' disabled={!isValidForm}>
            {lang === 'it' ? 'invia' : 'send'}
          </Button>
        </Grid2>
      </Grid2>
    </Paper>
  );
};
