import React, { useContext } from 'react';
import { Menu, MenuItem, SxProps, IconButton, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import useGlobalStyle from '../../config/styleHooks';
import AppCtx from '../../context';
import { scrollToTop } from '../../utils/utils';

type TBasicMenu = {
  listLinks: NavLinkType[];
  sx?: SxProps;
  icon?: React.ReactElement;
};

const useStyle = makeStyles((theme: Theme) => ({
  menuPaper: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },

  itemMenu: {
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

export default function BasicMenu({ listLinks, sx, icon }: TBasicMenu) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const classes = { ...useStyle(), ...useGlobalStyle() };
  const appContext = useContext(AppCtx);
  const setScrollPosition = appContext?.setScrollPosition;
  const yPos = appContext?.yPos;
  const lang = appContext?.lang || 'it';
  const { pathname } = useLocation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const linkClick = () => {
    console.log('yPos', yPos);
    setScrollPosition && setScrollPosition(0);
    scrollToTop();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={sx}>
      <IconButton color='inherit' aria-label='open drawer' edge='start' onClick={handleClick}>
        {icon ? icon : <MenuIcon />}
      </IconButton>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        classes={{ list: classes.menuPaper }}
      >
        {listLinks.map(({ link, name }) => (
          <MenuItem key={link} className={classes.itemMenu} onClick={handleClose}>
            <NavLink
              to={link}
              onClick={linkClick}
              className={({ isActive }) =>
                [classes.link, isActive && link === pathname ? classes.activeLink : ''].join(' ')
              }
            >
              {name[lang]}
            </NavLink>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
