import React, {
  MouseEventHandler,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import useGlobalStyle from "../../config/styleHooks";
import {
  Box,
  Pagination,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  CardActions,
  Button,
  List,
  ListItem,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import AppCtx from "../../context";
import { useLocation } from "react-router-dom";
import { default as data } from "./recensioni.json";
import usePagination from "../../utils/pagination";
import { TransitionProps } from "@mui/material/transitions";

function RecensioniPage() {
  const [page, setPage] = useState(1);
  const [dimension, setDimension] = useState({ height: 0 });
  const cardRef = useRef<HTMLDivElement>();
  const classes = useGlobalStyle();
  const appContext = useContext(AppCtx);
  const { pathname } = useLocation();
  const lang = appContext?.lang || "it";
  const mt = pathname === "/" ? 20 : 10;
  const theme = useTheme();
  const PER_PAGE = useMediaQuery(theme.breakpoints.up("md")) ? 3 : 1;
  const count = Math.ceil(data.length / PER_PAGE);

  const _DATA = usePagination(data, PER_PAGE);

  const handleChange = (e: any, p: any) => {
    setPage(p);
    _DATA.jump(p);
  };

  useEffect(() => {
    if (cardRef.current) {
      setDimension({ height: cardRef.current.offsetHeight });
      // console.log("cardRef.current", cardRef.current.offsetHeight);
    }
  }, [cardRef]);

  return (
    <Box mt={mt}>
      <Typography
        className={classes.titleCursive}
        color="primary"
        component="h1"
        variant="h3"
        textAlign="center"
        mb={5}
      >
        {lang === "it" ? "Dicono di me" : "They say about me"}
      </Typography>
      <Box display="flex" justifyContent={"center"} component={List}>
        {_DATA
          .currentData()
          .map(
            (
              { data, fonte, img, nome, recensione, titolo }: RecensioniData,
              i: number
            ) => {
              return (
                <ListItem key={i}>
                  <Card sx={{ height: "255px", width: "100%" }}>
                    <Box
                      component={CardContent}
                      ref={cardRef}
                      sx={{
                        ...(dimension.height > 200 && {
                          height: "205px",
                          overflow: "hidden",
                        }),
                        textOverflow: "ellipsis",
                      }}
                    >
                      <CardHeader
                        avatar={
                          <Avatar
                            alt={nome}
                            src={img}
                            sx={{ width: 56, height: 56 }}
                          />
                        }
                        title={
                          <Box>
                            <Typography component={"h1"} variant={"h6"}>
                              {nome}
                            </Typography>
                            <Typography variant="subtitle1">{data}</Typography>
                          </Box>
                        }
                      />
                      <Typography
                        variant="subtitle1"
                        sx={{ textTransform: "uppercase" }}
                        display="flex"
                        justifyContent="space-between"
                      >
                        Fonte: {fonte}
                      </Typography>
                      <Typography component={"h2"} variant={"h6"} mb={2}>
                        {titolo}
                      </Typography>

                      <Typography
                        paragraph
                        dangerouslySetInnerHTML={{ __html: recensione }}
                      />
                    </Box>
                    <CardActions
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {recensione.length > 120 && (
                        <RecensioneDialog recensione={recensione} />
                      )}
                    </CardActions>
                  </Card>
                </ListItem>
              );
            }
          )}
      </Box>
      <Stack mt={3} alignItems="center">
        <Pagination count={count} page={page} onChange={handleChange} />
      </Stack>
    </Box>
  );
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function RecensioneDialog({ recensione }: { recensione: string }) {
  const [open, setOpen] = React.useState(false);
  const appContext = useContext(AppCtx);
  const lang = appContext?.lang || "it";

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen}>
        {lang === "it" ? "altro ..." : "more ..."}
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="recensione-dialog"
      >
        <DialogContent>
          <DialogContentText id="recensione-dialog">
            {recensione}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            {lang === "it" ? "Chiudi" : "Close"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default RecensioniPage;
