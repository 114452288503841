import React, { useContext, useEffect } from 'react';
import { Container } from '@mui/material';
import Header from '../../../components/Header';
import AppCtx from '../../../context';
import Footer from '../../../components/Footer';
import { Outlet } from 'react-router-dom';

export default function Root() {
  const appContext = useContext(AppCtx);

  useEffect(() => {
    if (appContext) {
      document.documentElement.lang = appContext?.lang;
    }
  }, [appContext]);

  return (
    <Container maxWidth={false} disableGutters sx={{ overflow: 'hidden' }}>
      <Header />
      <Container maxWidth='xl' component='main' sx={{ my: 4, minHeight: '100vh' }}>
        <div id='top'></div>
        <Outlet />
      </Container>
      <Footer sx={{ mt: 4 }} />
    </Container>
  );
}
