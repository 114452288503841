import React from 'react';
import { Box, Button } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
interface IButtonStep {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  step: 'next' | 'back';
  maxSteps: number;
  activeStep: number;
}

const StepButton: React.FC<IButtonStep> = ({ setActiveStep, step, maxSteps, activeStep }) => {
  const handleClick = () => {
    setActiveStep((prevStep) => (step === 'next' ? prevStep + 1 : prevStep - 1));
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        alignItems: 'center',
        ...(step === 'next' ? { right: '10px' } : { left: '10px' }),
        display: { xs: 'none', lg: 'flex' },
      }}
    >
      {maxSteps && (
        <Button
          variant='contained'
          onClick={handleClick}
          sx={{
            borderRadius: '100%',
            height: '64px',
            opacity: '.5',
            backgroundColor: '#ffffff',
            color: 'black',
            '&.MuiButtonBase-root.Mui-disabled': {
              backgroundColor: '#fff',
              opacity: '.3',
            },
          }}
          disabled={step === 'next' ? activeStep === maxSteps - 1 : activeStep === 0}
        >
          {step === 'next' ? <ArrowForwardIosIcon /> : <ArrowBackIosNewIcon />}
        </Button>
      )}
    </Box>
  );
};

export default StepButton;
