const DintorniData: DintorniDataType[] = [
  {
    url: {
      it: 'https://it.wikipedia.org/wiki/Cattedrale_di_San_Sabino',
      en: 'https://en.wikipedia.org/wiki/Bari_Cathedral',
    },
    image:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/1/19/Bari_BW_2016-10-19_13-57-32.jpg/1280px-Bari_BW_2016-10-19_13-57-32.jpg',
    title: {
      it: 'Cattedrale di San Sabino',
      en: 'Bari Cathedral',
    },
    content: {
      it: `L'edificio attuale, che data tra il XII e il XIII secolo e probabilmente verso l'ultimo trentennio del millecento, fu costruito, per volontà dell'arcivescovo Rainaldo, sulle rovine del duomo bizantino distrutto da Guglielmo I detto il Malo (1156), del quale è possibile ancora oggi osservare a destra del transetto parte del pavimento originario che si estende sotto la navata centrale.
      Prima ancora del duomo bizantino, anche per la presenza della diocesi barese che risale infatti quanto meno al V secolo, quando è attestata la partecipazione del vescovo Concordio al Concilio Romano del 465, esisteva un'antica chiesa episcopale databile intorno al VI secolo, i cui resti si trovano sotto la navata centrale, come fa pensare uno dei mosaici pavimentali che contengono un'iscrizione in cui compare il nome del vescovo Andrea (758-761). Strutturata a tre navate, con pilastri quadrati e volte a crociera costruite con blocchi di pietra posti a spina di pesce, probabilmente si trovava nel luogo in cui sorge la cripta della cattedrale attuale, l'episcopio di Santa Maria. Ne conferma l'esistenza anche il ritrovamento di fondazioni di un edificio absidato il cui asse doveva essere disposto leggermente obliquo rispetto a quello dell'attuale cattedrale.
      In sostituzione di questa chiesa episcopale, nella prima metà dell'XI secolo l'arcivescovo Bisanzio (1025-1035) fece costruire una nuova chiesa terminata poi da Nicola I (1035-1061) e Andrea II (1061-1068), suoi successori. Questa chiesa fu poi distrutta da Guglielmo il Malo, durante la distruzione dell'intera città nel 1156 durante la quale fu risparmiata soltanto la basilica di San Nicola.  
      Per l'opera dell'edificio attuale furono usati materiali provenienti dalla chiesa precedente e da altri edifici distrutti. Consacrata il 4 ottobre 1292, la chiesa si rifà allo stile della Basilica di San Nicola. L'edificio ha poi subito una serie di rifacimenti, demolizioni ed aggiunte a partire dal XVIII secolo. Durante il XVIII secolo la facciata, l'interno delle navate, l'interno della Trulla (l'antico battistero del XII secolo, oggi sacrestia) e la cripta furono rifatte in forme barocche su progetto di Domenico Antonio Vaccaro. L'arredo interno fu invece riportato alle antiche fattezze romaniche negli anni cinquanta del XX secolo.`,
      en: `The documented presence of a bishop in Bari goes back to Gervasius, who attended the Council of Sardica in 347, and his successor Concordius, who was present at the Synod of Rome of 465, although names of their predecessors are preserved by tradition, beginning with Saint Maurus in the 1st century. The bishop was elevated to an archbishop in the 6th century, and the presence of a cathedral in Bari is ascertainable from the same period. Under the present nave are traces of an ancient church building with an apse, from the period before the first millennium, which had three aisles and square pilasters, and foundations on an axis slightly out of alignment with that of the present cathedral. One of the mosaic pavements bears an inscription with the name of Bishop Andrea (758 - 761) and it seems highly likely that these are the remains of the first cathedral, which was destroyed in the 9th or 10th century.
      In the first half of the 11th century the then archbishop (1025–1035) ordered the construction of a new episcopal church, which was finished under his successors Nicola I (1035–1061) and Andrea II (1061–1068). This church was then destroyed by William the Wicked (William I of Sicily) along with the rest of the city (only the Basilica of St. Nicholas was spared), which took place in 1156. At the end of the 12th century Archbishop Rainaldo began the reconstruction of the cathedral, reusing materials from the preceding church and other destroyed buildings. Consecrated on 4 October 1292, the new cathedral was built in the style of the Basilica of St. Nicholas, which had served as the episcopal seat in the interim.
      During the 18th century the façade, the nave and aisles, the Trulla (the ancient baptistry of the 12th century, today the sacristy) and the crypt were refurbished in Baroque style under Archbishop Muzio Gaeta according to designs by Domenico Antonio Vaccaro. The building later underwent a series of refurbishments, demolitions and extensions. The original Romanesque appearance of the interior was restored in the 1950s. The last two restorations were those of, firstly, the late 20th century, which has restored the clarity of the Romanesque structures, and secondly, the 21st century, which has refurbished the interior.`,
    },
  },
  {
    url: {
      it: 'https://it.wikipedia.org/wiki/Basilica_di_San_Nicola',
      en: 'https://en.wikipedia.org/wiki/Basilica_di_San_Nicola',
    },
    image:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Bari_BW_2016-10-19_13-35-11_stitch.jpg/1024px-Bari_BW_2016-10-19_13-35-11_stitch.jpg',
    title: {
      it: 'Basilica di San Nicola',
      en: 'Basilica di San Nicola',
    },
    content: {
      it: `La basilica di San Nicola, nel cuore della città vecchia di Bari, è un importante edificio di culto della Chiesa cattolica, tra i principali luoghi dell'ecumenismo con le Chiese ortodosse. Costituisce uno degli esempi più significativi di architettura del romanico pugliese.
      Si tratta di una basilica pontificia, ossia il suo affidamento ad un determinato ordine religioso spetta direttamente alla Santa Sede. Questo nuovo status giuridico risale al 1929 (Patti Lateranensi), quando lo Stato italiano, incamerando tutte le proprietà della basilica, rinunciava alla "palatinità" (gestione diretta del re sulla vita del clero) e immetteva la Santa Sede in questa giurisdizione.      
      Nel 1951, ponendo fine all'istituzione del capitolo dei canonici che serviva nella chiesa dalla prima metà del XII secolo, la basilica fu affidata all'Ordine domenicano che ancora presta il suo servizio prestando una particolare attenzione agli aspetti ecumenici.`,
      en: `The Pontifical Basilica of Saint Nicholas (Basilica of Saint Nicholas) is a church in Bari, Southern Italy that holds wide religious significance throughout Europe and the Christian world. The basilica is an important pilgrimage destination both for Roman Catholics and Orthodox Christians.`,
    },
    direction: 'row-reverse',
    animation: 'fade-left',
  },
  {
    url: {
      it: 'https://it.wikipedia.org/wiki/Castello_normanno-svevo_(Bari)',
      en: 'https://en.wikipedia.org/wiki/Castello_Normanno-Svevo_(Bari)',
    },
    image:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/4/4d/Castello_svevo_normanno_di_Bari.jpg/1280px-Castello_svevo_normanno_di_Bari.jpg',
    title: {
      it: 'Castello Svevo',
      en: 'Swabian Castle',
    },
    content: {
      it: `Il Castello normanno-svevo sorse intorno al 1131 per volontà di Ruggero il Normanno. Nel 1156 Guglielmo I di Sicilia lo distrusse quasi completamente e Guido il Vasto, per volontà di Federico II di Svevia, si occupò della sua ricostruzione.
      Il possente e grandioso castello consta di due parti distinte: la prima comprendente il mastio, di origine bizantino-normanna e trasformato da Federico II tra il 1233 e il 1240, a pianta trapezoidale con due torri delle quattro originarie; la seconda che ingloba i baluardi a scarpata con torrioni angolari a lancia sul fossato che furono addizionati nel XVI secolo sui tre lati dalla parte terrestre. Il lato Nord, quello marittimo, conserva il portale ogivale (ora murato) e le graziose bifore della costruzione duecentesca.      
      Al castello si accede dal lato sud, varcando il ponte sul fossato ed entrando nel cortile tra i baluardi cinquecenteschi e il mastio svevo, sulle cui torri e cortine costruite in bozze di pietra scura, si notano diverse monofore. Sul lato ovest un portale gotico scolpito immette in un atrio su colonne con volte a crociera, dal quale si passa nel cortile interno, quadrilatero, di impianto rinascimentale, assai rimaneggiato. In questo interno, sulla sinistra in un salone terreno è presente la gipsoteca all'interno della quale sono accolti numerosi calchi che ritraggono le più interessanti sculture architettoniche e decorative dei monumenti romanici di Puglia. Accanto, una interessante sala con volta a botte costolonata a sesto acuto, adibita ad archivio. Al piano superiore, nel lato meridionale del castello, vi sono vari ambienti ove è ubicata la Soprintendenza alle Gallerie. Il lato Nord ospita, in due ampie sale al piano superiore, i dipinti restaurati.`,
      en: `Probably built in 1132 by Norman King Roger II, it was destroyed in 1156 by king William I of Sicily and rebuilt and reinforced in 1233 by the Holy Roman emperor Fredrick II. During the Angevin domination, it went through several transformation, and after being acquired by Duke Ferdinand of Aragon, was donated to the Sforza family and passed to Bona Sforza, Queen of Poland. After Bona's death, it was returned under the King of Naples and transformed into a prison and barracks.
      The castle is surrounded by a moat on all sides, except the northern section, which was bordering the sea and can be accessed from the bridge and the gate on the southern side. It is mainly composed of the Aragon walls and the main Hohenstaufen tower, and is currently used for exhibitions.`,
    },
  },
  {
    url: {
      it: 'https://it.wikipedia.org/wiki/Monumenti_e_luoghi_d%27interesse_di_Bari#Spiagge',
      en: 'https://it.wikipedia.org/wiki/Monumenti_e_luoghi_d%27interesse_di_Bari#Spiagge',
    },
    image: 'https://upload.wikimedia.org/wikipedia/commons/b/b1/Bari_spiaggia_panepomodoro.JPG',
    title: {
      it: 'Spiaggia Pane Pomodoro',
      en: 'Beach Pane Pomodoro',
    },
    content: {
      it: `Bari, città marittima, vede la presenza di diversi lidi privati e pubblici distribuiti lungo tutto il litorale comunale. Le più rinomate e frequentate spiagge della città sono essenzialmente il celeberrimo Lido Pane e Pomodoro tristemente conosciuto per vicende legate all'inquinamento delle sue acque, e il Lido San Francesco.
      Il Lido Pane e Pomodoro, frequentatissimo dai baresi che vedono nel luogo punto in cui recarsi soprattutto nei mesi estivi quando il centro cittadino si trasforma in una vera e propria fornace, si trova poco distante dalla città nuova nelle vicinanze dei giardini di Piazza Antonio Gramsci. L'altro lido in cui grande parte della cittadinanza usa recarsi è il Lido San Francesco, a poca distanza dal Bosco di San Francesco alla Rena, presso la zona di punta San Cataldo in cui ha sede la pluri decennale rassegna della Fiera del Levante.      
      Adiacente al Lido San Francesco, esattamente al confine del rione San Girolamo, si trova il Torrente Derivatore Lamasinata nella cui foce sabbiosa, ogni estate, si viene ad insediare una moltitudine di bagnanti. Lo stanziamento di sedie ed ombrelloni è possibile in quanto il Torrente Derivatore Lamasinata è per la maggior parte del tempo asciutto: la sua funzione si espleta nel raccoglimento delle acque piovane provenienti dalla Murgia barese, garantendo uno sbocco al mare e scongiurando il pericolo di facili alluvioni. Il torrente in questione e, per estensione, la spiaggia libera ad esso pertinente sono conosciuti con il nome popolare di Canalone: "Andare al Canalone", in riferimento alla balneazione estiva, è un'espressione comune e radicata ormai da anni nel folklore barese.`,
      en: `Bari, a maritime city, sees the presence of several private and public beaches distributed along the entire municipal coast. The most famous and popular beaches in the city are essentially the famous Lido Pane e Pomodoro, sadly known for events related to the pollution of its waters, and Lido San Francesco.
      The Lido Pane e Pomodoro, very popular with the people of Bari who see the place to go especially in the summer months when the city center is transformed into a real furnace, is located not far from the new city near the gardens of Piazza Antonio Gramsci. The other beach where a large part of the citizens use to go is the Lido San Francesco, a short distance from the Bosco di San Francesco alla Rena, in the area of ​​Punta San Cataldo where the ten-year exhibition of the Fiera del Levante is located.      
      Adjacent to Lido San Francesco, exactly on the border of the San Girolamo district, there is the Derivatore Lamasinata stream in whose sandy mouth, every summer, a multitude of swimmers come to settle. The allocation of chairs and umbrellas is possible as the Torrente Derivatore Lamasinata is dry for most of the time: its function is carried out in the collection of rainwater from the Murgia of Bari, guaranteeing an outlet to the sea and averting the danger of easy floods . The stream in question and, by extension, the free beach pertaining to it are known by the popular name of Canalone: ​​"Going to the Canalone", referring to summer bathing, is a common expression that has been rooted for years in the Bari folklore.`,
    },
    direction: 'row-reverse',
    animation: 'fade-left',
  },
];

export default DintorniData;
