import React, { useContext, useEffect } from "react";
import AppCtx from "../../context";
import AboutPage from "../AboutPage";
import GalleryPage from "../GalleryPage";
import ServicesPages from "../ServicesPage";
import SurroundingsPage from "../SurroundingsPage";
import RecensioniPage from "../RecensioniPage";

export default function IndexPage() {
  const appContext = useContext(AppCtx);
  const yPos = appContext?.yPos;
  const setScrollPosition = appContext?.setScrollPosition;

  useEffect(() => {
    if (yPos) {
      window.scrollTo(0, yPos);
      setScrollPosition && setScrollPosition(0);
    }
  }, []);
  return (
    <>
      <AboutPage />
      <ServicesPages />
      <GalleryPage />
      <SurroundingsPage />
      <RecensioniPage />
    </>
  );
}
