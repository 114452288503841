import React from 'react';
import { Theme, Typography, Card as MuiCard, CardMedia, CardContent, Button, Link, useMediaQuery } from '@mui/material';
import useGlobalStyle from '../../config/styleHooks';
import cx from 'clsx';
import { makeStyles } from '@mui/styles';
import theme from '../../config/theme';

const useStyles = makeStyles<Theme>(({ breakpoints, spacing }) => ({
  root: {
    margin: 'auto',
    borderRadius: spacing(2), // 16px
    transition: '0.3s',
    boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',
    position: 'relative',
    marginLeft: 'auto',
    overflow: 'initial',
    background: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: spacing(2),
    marginBottom: '50px',
    [breakpoints.up('md')]: {
      paddingTop: spacing(2),
    },
  },
  media: {
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: spacing(-3),
    height: 0,
    paddingBottom: '48%',
    borderRadius: spacing(2),
    backgroundColor: '#fff',
    position: 'relative',
    [breakpoints.up('md')]: {
      width: '30%',
      marginLeft: spacing(-3),
      marginTop: 0,
      transform: 'translateX(-8px)',
    },
  },
  content: {
    maxWidth: '70%',
    display: 'flex',
    flexDirection: 'column',
    '& blockquote': {
      height: '400px',
      overflow: 'hidden',
      [breakpoints.up('md')]: {
        height: '100%',
      },
    },
    [breakpoints.up('md')]: {
      '& span#more': {
        display: 'none',
      },
    },
  },
}));

export default function Card({ animation, flexDirection = 'row', lang = 'it', url, image, title, content }: CardProps) {
  const classes = useGlobalStyle();
  const greaterThanMid = useMediaQuery(theme.breakpoints.up('md'));

  const styles = useStyles();
  const style = {
    root: {
      flexDirection: greaterThanMid ? flexDirection : 'column',
    },
    media: {
      marginRight: greaterThanMid ? (flexDirection === 'row-reverse' ? theme.spacing(-3) : '') : '',
      marginLeft: greaterThanMid ? (flexDirection === 'row-reverse' ? 'auto' : '') : '',
    },
  } as const;

  return (
    <MuiCard className={cx([styles.root])} data-aos={animation} style={style.root}>
      <CardMedia className={styles.media} image={image} style={style.media} />
      <CardContent className={styles.content}>
        <Typography variant='h4' component='h1' color='primary' className={classes.titleCursive} mb={4}>
          {title}
        </Typography>
        <Typography component='blockquote' cite={url} variant='body1' color='primary'>
          <Typography
            className={classes.titleCursive}
            display='inline'
            variant='h1'
            color='primary'
            height={{ xs: 40 }}
          >
            “
          </Typography>
          {content}
        </Typography>
        <span id='more'>...</span>
        <Typography
          className={classes.titleCursive}
          display='inline'
          textAlign='right'
          variant='h1'
          color='primary'
          height={{ xs: 40 }}
        >
          ”
        </Typography>

        <Typography component='cite' variant='body2' ml={2}>
          - {lang === 'it' ? 'Fonte' : 'Font'} Wikipedia
        </Typography>

        <Button variant='contained' sx={{ width: 150, margin: 'auto', marginTop: 4 }}>
          <Link underline='hover' textTransform='uppercase' href={url} color={'#fff'}>
            {lang === 'it' ? 'leggi altro' : 'read more'}
          </Link>
        </Button>
      </CardContent>
    </MuiCard>
  );
}
