import React from 'react';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  waves: {
    position: 'relative',
    width: '100%',
    marginBottom: -7,
    height: '5vw',
    minHeight: '5vw',
  },
  '@keyframes moveForever': {
    from: { transform: 'translate3d(-90px, 0, 0)' },
    to: { transform: 'translate3d(85px, 0, 0)' },
  },
  parallax: {
    '& > use': {
      animation: '$moveForever 4s cubic-bezier(0.62, 0.5, 0.38, 0.5) infinite',
      animationDelay: 2,
    },
  },
});

const WaveBorder = () => {
  const classes = useStyles();
  return (
    <div>
      <svg
        className={classes.waves}
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 24 150 28'
        preserveAspectRatio='none'
        shapeRendering='auto'
      >
        <defs>
          <path
            id='gentle-wave'
            fill='#0a5caf'
            d='M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z'
          />
        </defs>
        <g className={classes.parallax}>
          <use href='#gentle-wave' x='48' y='0' fill='#fff' />
        </g>
      </svg>
    </div>
  );
};

export default WaveBorder;
