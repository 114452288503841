import React, { useContext } from 'react';
import Carousel from '../../components/carousel';
import images from './images';
import { useMediaQuery, Box, Typography } from '@mui/material';
import theme from '../../config/theme';
import useGlobalStyle from '../../config/styleHooks';
import AppCtx from '../../context';
import { useLocation } from 'react-router-dom';

export default function GalleryPage() {
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const classes = useGlobalStyle();
  const appContext = useContext(AppCtx);
  const lang = appContext?.lang || 'it';
  const { pathname } = useLocation();
  const mt = pathname === '/' ? 20 : 10;

  return (
    <Box mt={mt}>
      <Typography
        className={classes.titleCursive}
        color='primary'
        component='h1'
        variant='h3'
        textAlign='center'
        mb={5}
      >
        {lang === 'it' ? 'Galleria' : 'Gallery'}
      </Typography>
      {images && (
        <Carousel
          photos={images}
          sx={{ backgroundColor: 'background.paper', marginTop: 2 }}
          randomize
          itemPerPage={isExtraSmallScreen ? 6 : 12}
        />
      )}
    </Box>
  );
}
