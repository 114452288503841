import React from 'react';
import { ImageListItem } from '@mui/material';

interface IItem {
  items: IPhoto[];
}

interface Props extends IItem {
  setIsOpenLightBox?: React.Dispatch<React.SetStateAction<boolean>>;
  page?: number;
  itemPerPage?: number;
  setIndexLightBox?: React.Dispatch<React.SetStateAction<number>>;
}
export default function Item({ items, setIsOpenLightBox, setIndexLightBox, page = 0, itemPerPage = 12 }: Props) {
  const handleClick = (index: number) => (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    if (setIndexLightBox) {
      setIndexLightBox(index);
    }
    if (setIsOpenLightBox) {
      setIsOpenLightBox(true);
    }
  };
  return (
    <>
      {items.map(({ id, src, name }, i) => (
        <ImageListItem
          key={id}
          cols={1}
          rows={1}
          onClick={handleClick(itemPerPage * page + i)}
          sx={{ ...(setIsOpenLightBox && { cursor: 'pointer' }) }}
        >
          <img src={src} alt={name} loading='lazy' data-aos='zoom-in' />
        </ImageListItem>
      ))}
    </>
  );
}
